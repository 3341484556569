<template>
  
  <header>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-between">
      <h1 class="text-3xl font-bold leading-tight text-gray-900">Instances</h1>
      <button type="button" @click="showCreate = true" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Create Instance</button>
    </div>
  </header>
  <main class="mt-6">
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="bg-white shadow overflow-hidden sm:rounded-md">
        <ul role="list" class="divide-y divide-gray-200" v-if="instances.length > 0">
          <InstanceItem :instance="instance" v-for="(instance, index) in instances" :key="index" />
        </ul>
        <div v-else>
          <div class="text-center py-8">
            
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto h-12 w-12 text-gray-400">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                </svg>
            <h3 class="mt-2 text-2xl font-medium text-gray-900">No Instances</h3>
            <p class="mt-1 text-base text-gray-500 mb-4">No instances found. Your created instances will appear here.</p>
            <button type="button" @click="showCreate = true" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Create Instance</button>
          </div>
        </div>
      </div>
    </div>
    <Teleport to="body">
      <dialog-modal :show="showCreate" @close="showCreate = false" :x-button="true">
            <template #title>Create Instance</template>
            <template #content>
                <div>
                  <div class="sm:col-span-6">
                    <label for="street-address" class="block text-sm font-medium text-gray-700"> Instance Name </label>
                    <div class="mt-1">
                      <input type="text" v-model="name" name="street-address" id="street-address" autocomplete="street-address" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                    </div>
                  </div>
                </div>
            </template>
            <template #footer>
              <div class="flex justify-end">
                <button type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" @click="showCreate = false">Cancel</button>
                <button type="button" class="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="confirmCreate">Create</button>
              </div>
            </template>
        </dialog-modal>
    </Teleport>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import InstanceItem from '@/components/InstanceItem.vue'
import DialogModal from '@/components/DialogModal.vue';
import * as slugify from 'slugify'
import axios from 'axios'
export default {
  name: 'HomeView',
  components:{
    InstanceItem,
    DialogModal
  },
  computed: {
    ...mapState({
      instances: state => state.instances
    })
  },
  data() {
    return {
      interval: null,
      showCreate: false,
      name: ''
    }
  },
  methods: {
    ...mapActions({
      fetchInstances: 'fetchInstances'
    }),
    async confirmCreate() {
      try {
          const api_key = document.getElementsByName('wapi-secret')[0].getAttribute('content')
          await axios.post(`${window.location.protocol}//${(process.env.NODE_ENV == 'development') ? 'localhost:3000' : window.location.host}/auth/register`, {
            instance_id: slugify(this.name)
          }, {
              headers: {
                  "api_key": api_key
              }
          })
          this.$notify({
              group: "foo",
              title: "Success",
              text: "Create instance command was sent successfully",
              status: 'success'
          }, 5000)
          this.name = ''
          this.showCreate = false
      } catch(err) {
          this.$notify({
              group: "foo",
              title: "Error",
              text: "Unable to send the create instance command",
              status: 'error'
          }, 5000)
      } finally {
          this.showConfirmRestart = false
      }
    },
    getInstances() {
      this.fetchInstances().catch(() => {
        this.$notify({
            group: "foo",
            title: "Error",
            text: "Failed to fetch instances from WhatsAPI Server",
            status: 'error'
        }, 5000)
      })
    },
    verifyLogin() {
      const hasPassword = document.getElementsByName('wapi-password')[0].getAttribute('content')
      if(hasPassword == 'yes') {
        const loginToken = localStorage.getItem('token')
        if(!loginToken) {
          this.$router.push({name: 'login'})
        }
        const expiration = atob(loginToken, 'base64').split('|')[1]
        const now = Date.now()
        if(expiration < now) {
          localStorage.removeItem('token')
          this.$router.push({name: 'login'})
        }
      }
    }
  },
  mounted() {
    this.verifyLogin()
    if(!this.interval) {
      this.interval = setInterval(() => {
        this.getInstances()
      }, 5000)
    }
    this.getInstances()
  },
  unmounted() {
    clearInterval(this.interval)
  }
}
</script>
