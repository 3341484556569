import axios from 'axios'
import { createStore } from 'vuex'

export default createStore({
  state: {
    instances: []
  },
  getters: {
  },
  mutations: {
    SET_INSTANCES(state, payload) {
      state.instances = payload
    }
  },
  actions: {
    fetchInstances: async ({commit}) => {
      return new Promise((resolve, reject) => {
        // let metaPort = document.getElementsByName('wapi-port')[0].getAttribute('content')
        // let port = metaPort === '$wapiport$' ? '3000' : metaPort
        axios.get(`${window.location.protocol}//${(process.env.NODE_ENV == 'development') ? 'localhost:3000' : window.location.host}/admin/instances`).then(response => {
          commit('SET_INSTANCES', response.data)
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    }
  }
})
