<template>
    <li>
        <div class="flex justify-between items-center">
            <span class="block cursor-default w-full">
            <div class="px-4 py-4 sm:px-6">
                <div class="flex items-center justify-between">
                <p class="text-sm font-medium text-indigo-600 truncate">{{instance.id}} <span v-if="instance.info">({{ instance.info.pushname }})</span></p>
                <div class="ml-2 flex-shrink-0 flex">
                    <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800" v-if="instance.info">Online</p>
                    <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800" v-else>Offline</p>
                </div>
                </div>
                <div class="mt-2 sm:flex sm:justify-between">
                <div class="sm:flex">
                    <template v-if="instance.info">
                    <p class="flex items-center text-sm text-gray-500">
                        

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                        </svg>

                        {{instance.info.wid.user}}
                    </p>
                    <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                        </svg>

                        {{instance.info.platform}}
                    </p>

                    <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                        </svg>


                        v{{instance.version}}
                    </p>
                    </template>
                </div>
                <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                    
                    <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                    </svg>
                    <p>
                    Created at
                    {{instance.createdAt}}
                    </p>
                </div>
        </div>
        </div>
    </span>
    <div class="pr-4 flex items-center space-x-2">
        <button type="button" @click="showDetails = true" class="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
            </svg>

        </button>
        
        <button type="button" @click="showConfirmRestart = true" class="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
        </button>

        <button type="button" @click="showConfirmDelete = true" class="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
            </svg>
        </button>

    </div>

    </div>
    <Teleport to="body">
        <dialog-modal :show="showConfirmDelete" @close="showConfirmDelete = false">
            <template #title>Terminate Instance</template>
            <template #content>
                Are you sure you want to terminate the instance <strong>"{{ instance.id }}"</strong>?
            </template>
            <template #footer>
                <secondary-button @click="showConfirmDelete = false" class="mr-2">Cancel</secondary-button>
                <primary-button @click="confirmDelete">Confirm</primary-button>
            </template>
        </dialog-modal>
        <dialog-modal :show="showConfirmRestart" @close="showConfirmRestart = false">
            <template #title>Restart Instance</template>
            <template #content>
                Are you sure you want to restart the instance <strong>"{{ instance.id }}"</strong>?
            </template>
            <template #footer>
                <secondary-button @click="showConfirmRestart = false" class="mr-2">Cancel</secondary-button>
                <primary-button @click="confirmRestart">Confirm</primary-button>
            </template>
        </dialog-modal>
        <dialog-modal :show="showDetails" @close="showDetails = false" :x-button="true">
            <template #title>Instance Details</template>
            <template #content>
                <div>
                    <div class="border-gray-200">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                <dt class="text-sm font-medium text-gray-500">Instance ID</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ instance.id }}</dd>
                            </div>
                            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                <dt class="text-sm font-medium text-gray-500">Status</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800" v-if="instance.info">Online</p>
                                    <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800" v-else>Offline</p>    
                                </dd>
                            </div>
                            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" v-if="instance.info">
                                <dt class="text-sm font-medium text-gray-500">Device Number</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ instance.info.wid.user }}</dd>
                            </div>
                            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" v-if="instance.info">
                                <dt class="text-sm font-medium text-gray-500">WhatsApp Web Version</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">v{{instance.version}}</dd>
                            </div>
                            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" v-if="instance.info">
                                <dt class="text-sm font-medium text-gray-500">Platform</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{instance.info.platform}}</dd>
                            </div>
                            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                <dt class="text-sm font-medium text-gray-500">Instance Secret</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center space-x-2">
                                    <div :style="showSecret ? '' : 'color: transparent; text-shadow: 0 0 8px #000; user-select: none'">{{instance.secret}}</div>
                                    <svg @click="showSecret = !showSecret" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4" v-if="!showSecret">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                    <svg @click="showSecret = !showSecret" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4" v-else>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                    </svg>

                                </dd>
                            </div>
                            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" v-if="!instance.info">
                                <dt class="text-sm font-medium text-gray-500">Last QR Code</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <img :src="qr" alt="" class="w-128 h-128" v-if="qr">
                                    <div v-else>
                                        No QR Code received yet
                                    </div>
                                </dd>
                            </div>
                        </dl>
                    </div>
                    </div>
            </template>
        </dialog-modal>
    </Teleport>
    </li>
</template>
<script>
import axios from 'axios';
import DialogModal from './DialogModal.vue';
import PrimaryButton from './PrimaryButton.vue';
import SecondaryButton from './SecondaryButton.vue';
import * as QRCode from 'qrcode'
export default {
    name: 'InstanceItem',
    components: {
        DialogModal,
        PrimaryButton,
        SecondaryButton
    },
    props: {
        instance: {
            required: true,
            type: Object
        }
    },
    mounted() {
        this.fetchQr()
        this.fetchMethod = setInterval(() => {
            if(this.showDetails) {
                this.fetchQr()
            }
        }, 5000)
    },
    unmounted() {
        clearInterval(this.fetchMethod)
    },
    data() {
        return {
            showConfirmDelete: false,
            showConfirmRestart: false,
            showDetails: false,
            showSecret: false,
            fetchMethod: null,
            qr: ''
        }
    },
    methods: {
        async confirmRestart() {
            try {
                const api_key = document.getElementsByName('wapi-secret')[0].getAttribute('content')
                await axios.post(`${window.location.protocol}//${(process.env.NODE_ENV == 'development') ? 'localhost:3000' : window.location.host}/auth/restart`, {}, {
                    headers: {
                        "x-instance-id": this.instance.id,
                        "api_key": api_key
                    }
                })
                this.$notify({
                    group: "foo",
                    title: "Success",
                    text: "The restart command was sent successfully",
                    status: 'success'
                }, 5000)
            } catch(err) {
                this.$notify({
                    group: "foo",
                    title: "Error",
                    text: "Unable to send the restart command",
                    status: 'error'
                }, 5000)
            } finally {
                this.showConfirmRestart = false
            }
        },
        async fetchQr() {
            try {
                const api_key = document.getElementsByName('wapi-secret')[0].getAttribute('content')
                const {data} = await axios.get(`${window.location.protocol}//${(process.env.NODE_ENV == 'development') ? 'localhost:3000' : window.location.host}/auth/getqr`, {
                    headers: {
                        "x-instance-id": this.instance.id,
                        "api_key": api_key
                    }
                })
                QRCode.toDataURL(data.qrcode,  (err, url) => {
                    this.qr = url
                })
                
            } catch(err) {
                console.error(err)
                this.$notify({
                    group: "foo",
                    title: "Error",
                    text: "Unable to fetch QRCode",
                    status: 'error'
                }, 5000)
            }
        },
        async confirmDelete() {
            try {
                const api_key = document.getElementsByName('wapi-secret')[0].getAttribute('content')
                await axios.delete(`${window.location.protocol}//${(process.env.NODE_ENV == 'development') ? 'localhost:3000' : window.location.host}/auth/terminate`, {
                    headers: {
                        "x-instance-id": this.instance.id,
                        "api_key": api_key
                    }
                })
                this.$notify({
                    group: "foo",
                    title: "Success",
                    text: "The terminate command was sent successfully",
                    status: 'success'
                }, 5000)
            } catch(err) {
                this.$notify({
                    group: "foo",
                    title: "Error",
                    text: "Unable to send the terminate command",
                    status: 'error'
                }, 5000)
            } finally {
                this.showConfirmDelete = false
            }
        }
    }
}
</script>