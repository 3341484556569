<template>
    <modal-layout :show="show" :max-width="maxWidth" :closeable="closeable" @close="close" :x-button="xButton">
        <div class="">
            <div class="text-lg font-semibold bg-gray-200 text-gray-900 px-6 py-2 capitalize flex justify-between items-center pr-5">
                <slot name="title">
                </slot>
                <div v-if="xButton" class="cursor-pointer" @click="$emit('close')">
                    <XMarkIcon class="w-5 h-5 text-gray-600" />
                </div>
            </div>

            <div class="mt-4 px-6 pb-4">
                <slot name="content">
                </slot>
            </div>
        </div>

        <div class="px-6 py-4 border-t text-right" v-if="$slots['footer']">
            <slot name="footer">
            </slot>
        </div>
        <div v-else class="pb-1">

        </div>
    </modal-layout>
</template>

<script>
    import ModalLayout from './ModalLayout'
    import { XMarkIcon } from '@heroicons/vue/24/outline'
    export default {
        components: {
            ModalLayout,
            XMarkIcon
        },
        props: {
            xButton: {
                default: false
            },
            show: {
                default: false
            },
            maxWidth: {
                default: '2xl'
            },
            closeable: {
                default: false
            },
        },
        methods: {
            close() {
                if(this.closeable) {
                    this.$emit('close')
                }
            },
        }
    }
</script>