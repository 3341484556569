<template>
  <div class="min-h-full">
    <NotificationGroup group="foo">
    <div class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
      <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <Notification
        v-slot="{ notifications, close }"
        enter="transform ease-out duration-300 transition"
        enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leave-from="opacity-100"
        leave-to="opacity-0"
        move="transition duration-500"
        move-delay="delay-300"
      >
        <div
          class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
          v-for="notification in notifications"
          :key="notification.id"
        >
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/check-circle -->
              <svg class="h-6 w-6 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" v-if="notification.status == 'success'">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-red-400" v-else>
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
              </svg>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900">{{notification.title}}</p>
              <p class="mt-1 text-sm text-gray-500">{{notification.text}}</p>
            </div>
            <div class="ml-4 flex-shrink-0 flex">
              <button class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span class="sr-only">Close</span>
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" @click="close">
                  <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        </div>
      </Notification>
    </div>
    </div>
  </NotificationGroup>
    <nav class="bg-white shadow-sm">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="flex-shrink-0 flex items-center">
              <div class="font-bold text-lg">
                <img src="https://ww.norse.link/logo.png" class="h-8" alt="">
              </div>
              <!-- <div class="font-bold text-lg">Wa Messaging Api</div> -->
            </div>
            <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
              <a href="#" class="border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium" aria-current="page">Dashboard</a>
              <a href="javascript:;" v-if="isLoggedIn" @click="logout" class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">Logout</a>
            </div>
          </div>
          
          <div class="-mr-2 flex items-center sm:hidden">
            <button type="button" class="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" aria-controls="mobile-menu" aria-expanded="false">
              <span class="sr-only">Open main menu</span>
              <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>

    </nav>

    <div class="py-10">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoggedIn: false
    }
  },
  mounted() {
    this.verifyLogin()
    this.registerDb()
  },
  watch: {
    '$router': {
      handler() {
        this.verifyLogin()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    registerDb() {
      // Check if IndexedDB is supported by the browser
      if (!window.indexedDB) {
        console.log("Your browser doesn't support IndexedDB");
      } else {
        // Open a connection to the database (or create it if it doesn't exist)
        const request = window.indexedDB.open("waMetrics", 1);

        // Handle errors that may occur while opening the database
        request.onerror = function(event) {
          console.log("Failed to open database", event.target.error);
        };

        // Create the object store and indexes when the database is first created
        request.onupgradeneeded = function(event) {
          const db = event.target.result;

          // Create the object store
          const objectStore = db.createObjectStore("metrics", { keyPath: "id", autoIncrement: true });

          // Create an index to allow for searching by a particular property
          objectStore.createIndex("timestamp", "timestamp", { unique: false });
        };

        // Log a success message when the database is successfully opened
        request.onsuccess = function() {
          console.log("Database opened successfully");
        };
      }
    },
    verifyLogin() {
      const hasPassword = document.getElementsByName('wapi-password')[0].getAttribute('content')
      if(hasPassword == 'yes') {
        const loginToken = localStorage.getItem('token')
        if(loginToken) {
          const expiration = atob(loginToken, 'base64').split('|')[1]
          const now = Date.now()
          if(expiration < now) {
            this.isLoggedIn = false
          } else {
            this.isLoggedIn = true
          }
        }
      }
    },
    logout() {
      this.isLoggedIn = false
      localStorage.removeItem('token')
      this.$router.push({name: 'login'})
    }
  }
}
</script>