<template>
    <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <div>
          <label for="password" class="block text-sm font-medium text-gray-700"> Password </label>
          <div class="mt-1">
            <input id="password" name="password" v-model="password" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          </div>
        </div>

        <div class="mt-4">
          <button @click="login" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Sign in</button>
        </div>

    </div>
  </div>
</div>
</template>
<script>
import axios from 'axios'

export default {
    mounted() {
        this.verifyLogin()
    },
    data() {
        return {
            password: ''
        }
    },
    methods: {
        verifyLogin() {
            const hasPassword = document.getElementsByName('wapi-password')[0].getAttribute('content')
            if(hasPassword == 'no') {
                this.$router.push({name: 'home'})
            }
        },
        async login() {
            const api_key = document.getElementsByName('wapi-secret')[0].getAttribute('content')
            try {
                const {data} = await axios.post(`${window.location.protocol}//${(process.env.NODE_ENV == 'development') ? 'localhost:3000' : window.location.host}/auth/login`, {
                    password: this.password
                }, {
                    headers: {
                        "api_key": api_key
                    }
                })
                if(data.token) {
                    localStorage.setItem('token', data.token)
                    this.$router.push({name: 'home'})
                } else {
                    this.$notify({
                        group: "foo",
                        title: "Ops!",
                        text: "Login failed!",
                        status: 'error'
                    }, 5000)
                }
            } catch(err) {
                this.$notify({
                    group: "foo",
                    title: "Ops!",
                    text: "Login failed!",
                    status: 'error'
                }, 5000)
            }
        }
    }
}
</script>